import * as React from "react";
import { Layout, LayoutDescription, LayoutHeader, LayoutTitle, SegmentedContent } from "@brainfinance/icash-component-library";
import { SegmentedContentProps } from "./segmented-content";
import { generateTitle } from "@helpers/generateTitle";
// Images
import icon from "@images/icash-symbol-white.svg";
import defaultImg from "@images/default.png";

export const SegmentedContentSection = (props: SegmentedContentProps) => (
  <Layout>
    <LayoutHeader>
      <LayoutTitle>
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription>{props.description}</LayoutDescription>
    </LayoutHeader>
    <SegmentedContent logo={props.logo} segmentedData={props.segmentedData} />
  </Layout>
);

SegmentedContentSection.defaultProps = {
  title: "Payday loans online",
  gray: "online",
  description:
    "iCash has partnered with some of the most trusted companies in their respective industries. Keeping our customers personal information safe is our utmost priority.",
  src: defaultImg,
  logo: icon
};
