import * as React from "react";

import { LayoutProps } from "@types";
import { generateTitle } from "../../helpers";
import { FlowPresentation, FlowPresentationTitle } from "@brainfinance/icash-component-library";

export const FlowPresentationSection = (props: React.PropsWithChildren<LayoutProps>) => (
  <FlowPresentation>
    <FlowPresentationTitle>
      {generateTitle({
        title: props.title,
        gray: props.gray,
      })}
    </FlowPresentationTitle>
    <div className="flow-presention-card--container">
      {props.children}
    </div>
  </FlowPresentation>
);

FlowPresentationSection.defaultProps = {
  title: "The greatest alternative to traditional payday loans",
  gray: "greatest alternative"
};
