import * as React from "react";
import { graphql } from "gatsby";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

// Images
import applyOnlineImg from "@images/flow-presentation-img/mobile-loans.png";
import instantDecisionImg from "@images/flow-presentation-img/mobile-loan-approved.png";
import getFundsImg from "@images/flow-presentation-img/mobile-money-sent.png";

// Components
import { PageLayout, SEO } from "@components/page-layout";
import {
  GrayPhrase,
  Heading,
  PageHeading,
  Paragraph,
  Subheading,
} from "@components/typography";
import { SideHeader } from "@containers/header";
import { CompareTableSection } from "@containers/compare-table";
import { Testimonials } from "@containers/testimonials";
import { Box } from "@components/box";
import { FullscreenSection } from "@containers/fullscreen";
import { FlowPresentationSection } from "@containers/flow-presentation";
import { StatisticsList } from "@containers/statistics";
import { Paragraphs } from "@containers/paragraphs";
import { generateTitle } from "../helpers";
import { SegmentedContentSection } from "@containers/segmented-content";
import { generateLink } from "../helpers/generateLink";
import { FlowPresentationCard, ReadMore, ReadMoreSidePanel, ReadMoreText } from "@brainfinance/icash-component-library";

const CategoriesTemplate = (props: any) => {
  const { contentfulCategories }: any = props.data;

  const headerTitle = (title: string, gray?: string) => {
    if (gray) {
      if (title.includes(gray)) {
        const titleArray = title.split(gray);
        return (
          <>
            {titleArray[0]}
            <br />
            <GrayPhrase>{gray}</GrayPhrase>
            <br />
            {titleArray[1]}
          </>
        );
      }
    }

    return { title };
  };

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imageID = node.data.target.sys.id;
        const {
          file: { url },
        } = contentfulCategories.body.references.find(
          ({ contentful_id }: { contentful_id: string | number }) =>
            contentful_id === imageID
        );

        return (
          <Box display="flex" className="w-full justify-center p-[2rem]">
            <img className="w-4/5 md:w-full" src={url} />
          </Box>
        );
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="py-[1.5rem]">
          <Heading>{children}</Heading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Box className="py-[1rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Box className="py-[0.75rem]">
          <Paragraph className="text-interface-300">{children}</Paragraph>
        </Box>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>,
    },
  };

  const stepsData = [
    {
      step: 1,
      title: contentfulCategories.step1title,
      children: contentfulCategories.step1Text.step1Text,
      image: applyOnlineImg,
    },
    {
      step: 2,
      title: contentfulCategories.step2Title,
      children: contentfulCategories.step2Text.step2Text,
      image: instantDecisionImg,
    },
    {
      step: 3,
      title: contentfulCategories.step3Title,
      children: contentfulCategories.step3Text.step3Text,
      image: getFundsImg,
    },
  ];

  const paragraphsData = [
    {
      title: contentfulCategories.firstParagraphTitle,
      description: generateLink(
        contentfulCategories.firstParagraphText.firstParagraphText
      ),
    },
    {
      title: contentfulCategories.secondParagraphTitle,
      description: generateLink(
        contentfulCategories.secondParagraphText.secondParagraphText
      ),
    },
    {
      title: contentfulCategories.thirdParagraphTitle,
      description: generateLink(
        contentfulCategories.thirdParagraphText.thirdParagraphText
      ),
    },
    {
      title: contentfulCategories.fourthParagraphTitle,
      description: generateLink(
        contentfulCategories.fourthParagraphText.fourthParagraphText
      ),
    },
    {
      title: contentfulCategories.fifthParagraphTitle,
      description: generateLink(
        contentfulCategories.fifthParagraphText.fifthParagraphText
      ),
    },
  ];

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <PageLayout>
      <SEO
        title={contentfulCategories.metaTitle}
        description={contentfulCategories.metaDescription.metaDescription}
      />
      <SideHeader
        src={contentfulCategories.headerImage?.gatsbyImageData.images.fallback.src}
        srcSet={contentfulCategories.headerImage?.gatsbyImageData.images.fallback.srcSet}
        mediumImg
        description=""
      >
        {headerTitle(contentfulCategories.h1, contentfulCategories.h1Gray)}
      </SideHeader>
      <ReadMore>
        {generateTitle(
          contentfulCategories.h2,
          contentfulCategories.h2Gray
        )}
        <ReadMoreText className="mt-[2.5rem]">
          {renderRichText(contentfulCategories.mainText, options)}
        </ReadMoreText>
        <ReadMoreSidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
          {renderRichText(contentfulCategories.mainText, options)}
        </ReadMoreSidePanel>
      </ReadMore>
      {/* Trustpilot */}
      <FullscreenSection />
      <FlowPresentationSection
        title={contentfulCategories.threeStepsTitle}
        gray={contentfulCategories.stepsGrayTitle}
      >
        {stepsData.map((flowPresentation, ndx) => (
          <FlowPresentationCard key={ndx} {...flowPresentation} />
        ))}
      </FlowPresentationSection>
      <StatisticsList
        title={contentfulCategories.statisticsTitle}
        description={contentfulCategories.statisticsText.statisticsText}
      />
      <CompareTableSection
        title={contentfulCategories.compareTableTitle}
        gray={contentfulCategories.compareTableGrayTitle}
        description={contentfulCategories.compareTableText.compareTableText}
      />
      <Paragraphs
        title={contentfulCategories.paragraphTitle}
        gray={contentfulCategories.paragraphGrayTitle}
        description={
          contentfulCategories.paragraphDescription.paragraphDescription
        }
        contentData={paragraphsData}
      />
      <Testimonials
        title={contentfulCategories.testimonialsTitle}
        gray={contentfulCategories.testimonialsGrayTitle}
        description={
          contentfulCategories.testimonialsDescription.testimonialsDescription
        }
      />

      <SegmentedContentSection
        title={contentfulCategories.segmentedContentTitle}
        description={
          contentfulCategories.segmentedContentDescription
            .segmentedContentDescription
        }
        segmentedData={contentfulCategories.segmentedContentData}
      />
    </PageLayout>
  );
};

export default CategoriesTemplate;

export const pageQuery = graphql`
  query CategoriesQuery($slug: String!) {
    contentfulCategories(slug: { eq: $slug }) {
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      h1
      h1Gray
      h2
      h2Gray
      headerImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160, quality: 70)
        title
      }
      mainText {
        raw
      }
      threeStepsTitle
      stepsGrayTitle
      step1Title
      step1Text {
        step1Text
      }
      step2Title
      step2Text {
        step2Text
      }
      step3Title
      step3Text {
        step3Text
      }
      statisticsTitle
      statisticsGrayTitlePart
      statisticsText {
        statisticsText
      }
      compareTableTitle
      compareTableGrayTitle
      compareTableText {
        compareTableText
      }
      paragraphTitle
      paragraphGrayTitle
      paragraphDescription {
        paragraphDescription
      }
      paragraphAllParagraphs {
        title
        description
      }
      firstParagraphTitle
      firstParagraphText {
        firstParagraphText
      }
      secondParagraphTitle
      secondParagraphText {
        secondParagraphText
      }
      thirdParagraphTitle
      thirdParagraphText {
        thirdParagraphText
      }
      fourthParagraphTitle
      fourthParagraphText {
        fourthParagraphText
      }
      fifthParagraphTitle
      fifthParagraphText {
        fifthParagraphText
      }
      testimonialsTitle
      testimonialsGrayTitle
      testimonialsDescription {
        testimonialsDescription
      }
      segmentedContentTitle
      segmentedContentDescription {
        segmentedContentDescription
      }
      segmentedContentData {
        label
        text
      }
    }
  }
`;
